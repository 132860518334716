/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { CusImage } from 'components/index';
import { FC } from 'react';
import { photoType } from 'type/home/product';

interface IPhotoListProps {
  selectImg: number;
  onSelectImg: (index: number) => void;
  list: photoType[] | undefined;
  wh?: number;
  gap?: number;
  isVertical?: boolean;
  className?: string;
}

export const PhotoList: FC<IPhotoListProps> = ({
  selectImg,
  onSelectImg,
  list,
  wh = 85,
  gap = 16,
  isVertical = true,
  className = '',
}) => {
  const intemDivClass = (index: number) => {
    return selectImg === index ? 'img-div selected' : 'img-div';
  };

  return (
    <Flex
      vertical={isVertical}
      flex="0 0 auto"
      // justify="center"
      gap={gap}
      css={cssPhotoList(wh)}
      className={className}
    >
      {list?.map((item, index) => (
        <div
          key={index}
          className={intemDivClass(index)}
          onClick={() => onSelectImg(index)}
        >
          <CusImage
            src={item?.photoUrl}
            alt={item?.photoName}
            borderRadius={20}
          />
        </div>
      ))}
    </Flex>
  );
};

const cssPhotoList = (wh: number) => css`
  > .img-div {
    width: ${wh}px;
    height: ${wh}px;
    border-radius: 20px;
    border: 1px solid var(--grey-20);
    overflow: hidden;
    :is(.selected) {
      border: 3px solid #000;
    }
    cursor: pointer;
  }
`;
