/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ConfigProvider, Divider } from 'antd';
import { FC } from 'react';
interface ICusDividerProps {
  type?: 'horizontal' | 'vertical';
  marginBlock?: number;
  dashed?: boolean;
  variant?: 'dashed' | 'solid' | 'dotted';
  orientation?: 'left' | 'right' | 'center';
  plain?: boolean;
}

export const CusDivider: FC<ICusDividerProps> = ({
  type = 'horizontal',
  marginBlock = 20,
  dashed = false,
  variant = 'solid',
  orientation = 'center',
  plain = false,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Divider: {
            // orientationMargin: 0.05,
            // textPaddingInline: '1em',
            // verticalMarginInline: 8,
          },
        },
      }}
    >
      <Divider
        type={type}
        dashed={dashed}
        variant={variant}
        orientation={orientation}
        plain={plain}
        css={cssDivider(marginBlock)}
      />
    </ConfigProvider>
  );
};

const cssDivider = (marginBlock: number) => css`
  margin: ${marginBlock}px 0;
`;
