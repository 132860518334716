import { useParams } from 'react-router-dom';
import { useGetProductData } from 'services/productsService';

const useProduct = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetProductData(Number(id));

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export { useProduct };
