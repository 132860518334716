/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { CusButton, CusDivider, CusParagraph } from 'components/index';
import { useOptions } from 'hooks/index';
import { useQrCode } from 'hooks/md/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { productDataType } from 'type/home/product';

interface IItemInfoProps {
  data: productDataType | undefined;
  className?: string;
}

export const ItemInfo: FC<IItemInfoProps> = ({ data, className = '' }) => {
  const { t } = useTranslation(['content', 'action']);
  const { onOpenQrCode } = useQrCode();
  const { statusOptions, ageOptions } = useOptions();
  const statueText = statusOptions.find((item) => item.key === data?.condition)
    ?.label as string;

  const ageText = ageOptions
    ?.filter((item) => data?.suitableAges?.includes(item.key))
    .map((item) => item.label)
    .join(', ');

  const isFree = data?.price === 0;

  return (
    <Flex vertical flex="1 1 auto" className={className} css={cssItemInfo}>
      <CusParagraph
        text={data?.name}
        size={24}
        weight={600}
        color="#000"
        ellipsis={false}
        marginBlock="0px 8px"
      />
      <CusParagraph
        text={isFree ? t('content:free') : Number(data?.price).toFixed(2)}
        size={30}
        prefix={isFree ? '' : '$'}
        weight={700}
        color={isFree ? 'var(--any-primary-60)' : 'var(--grey-70)'}
        marginBlock="0px 8px"
      />
      <Flex flex="0 0 auto" className="status-div">
        <StatusDiv text={statueText} />
      </Flex>
      <CusButton
        text={t('action:download-app-talking')}
        isBlock={true}
        onClick={onOpenQrCode}
      />
      <CusDivider marginBlock={24} />
      <CusParagraph
        text={t('content:product-desc')}
        size={16}
        weight={600}
        color="var(--grey-60)"
        marginBlock="0px 8px"
      />
      <CusParagraph
        text={data?.description}
        size={17}
        color="var(--grey-50)"
        ellipsis={false}
        marginBlock="0px 24px"
      />
      <CusParagraph
        text={t('content:age-appropriate')}
        size={16}
        weight={600}
        color="var(--grey-60)"
        marginBlock="0px 8px"
      />
      <CusParagraph
        text={ageText}
        size={17}
        color="var(--grey-50)"
        ellipsis={false}
        marginBlock="0px 24px"
      />
      <CusParagraph
        text={t('content:deal-location')}
        size={16}
        weight={600}
        color="var(--grey-60)"
        marginBlock="0px 8px"
      />
      <CusParagraph text={data?.pickupCity} size={16} color="var(--grey-50)" />
    </Flex>
  );
};

const cssItemInfo = css`
  height: 100%;
  margin-left: 30px;
  > .status-div {
    margin-bottom: 24px;
  }
  @media (max-width: 1100px) {
    margin-left: 0px;
    max-width: 430px;
  }
`;

const StatusDiv: FC<{ text: string }> = ({ text }) => {
  return <div css={cssStatusDiv}>{text}</div>;
};

const cssStatusDiv = css`
  padding: 4px 10px;
  border-radius: 50px;
  background: var(--grey-20);
`;
