import { selectModal, setQrCodeOpen } from 'store/modalSlice';
import { useSelector, useDispatch } from 'react-redux';

const useQrCode = () => {
  const dispatch = useDispatch();
  const { qrCodeOpen } = useSelector(selectModal);

  const onOpenQrCode = () => {
    dispatch(setQrCodeOpen(true));
  };

  const onCloseQrCode = () => {
    dispatch(setQrCodeOpen(false));
  };

  return { qrCodeOpen, onOpenQrCode, onCloseQrCode };
};

export { useQrCode };
