import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    qrCodeOpen: false,
    photoOpen: false,
    photos: [],
  },
  reducers: {
    setQrCodeOpen: (state, action) => {
      state.qrCodeOpen = action.payload;
    },
    setPhotoOpen: (state, action) => {
      state.photoOpen = action.payload;
    },
    setPhotos: (state, action) => {
      state.photos = action.payload;
    },
  },
});

export default modalSlice.reducer;
export const selectModal = (state: any) => state.modal;

export const { setQrCodeOpen, setPhotoOpen, setPhotos } = modalSlice.actions;
