import { useTranslation } from 'react-i18next';
import {
  ageDropDownType,
  conditionDropDownType,
} from 'type/components/dropdown';

const useOptions = () => {
  const { t } = useTranslation('options');
  const statusOptions: conditionDropDownType[] = [
    {
      key: 'brandNew',
      label: t('options:brandNew'),
    },
    {
      key: 'likeNew',
      label: t('options:likeNew'),
    },
    {
      key: 'good',
      label: t('options:good'),
    },
    {
      key: 'gentlyUsed',
      label: t('options:gentlyUsed'),
    },
    {
      key: 'fairCondition',
      label: t('options:fairCondition'),
    },
  ];

  const ageOptions: ageDropDownType[] = [
    {
      key: 'zeroTo6',
      label: t('options:zeroTo6'),
    },
    {
      key: 'sixTo12',
      label: t('options:sixTo12'),
    },
    {
      key: 'twelveTo18',
      label: t('options:twelveTo18'),
    },
    {
      key: 'eighteenTo24',
      label: t('options:eighteenTo24'),
    },
    {
      key: 'twoPlus',
      label: t('options:twoPlus'),
    },
    {
      key: 'threePlus',
      label: t('options:threePlus'),
    },
    {
      key: 'fourPlus',
      label: t('options:fourPlus'),
    },
    {
      key: 'fivePlus',
      label: t('options:fivePlus'),
    },
    {
      key: 'sixPlus',
      label: t('options:sixPlus'),
    },
    {
      key: 'sevenPlus',
      label: t('options:sevenPlus'),
    },
  ];

  return {
    statusOptions,
    ageOptions,
  };
};

export { useOptions };
