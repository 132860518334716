import { CusSpin } from 'components/index';
import Product from 'pages/product/Product';
import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const Main = lazy(() => import('pages/Main'));
const Home = lazy(() => import('pages/home/Home'));
const AboutUs = lazy(() => import('pages/aboutUs/AboutUs'));
const TermOfService = lazy(() => import('pages/termsOfService/TermOfService'));
const PrivacyPolicy = lazy(() => import('pages/privacyPolicy/PrivacyPolicy'));
// const Product = lazy(() => import('pages/product/Product'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense>
        <Main />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<CusSpin spinning={true} fullscreen={true} />}>
            <Home />
          </Suspense>
        ),
        // children: [
        // ],
        errorElement: <div>Not Found</div>,
      },
      {
        path: 'product/:id',
        element: <Product />,
        errorElement: <div>Not Found</div>,
      },
      {
        path: 'aboutus',
        element: (
          <Suspense fallback={<CusSpin spinning={true} fullscreen={true} />}>
            <AboutUs />
          </Suspense>
        ),
        errorElement: <div>Not Found</div>,
      },
      {
        path: 'terms',
        element: (
          <Suspense fallback={<CusSpin spinning={true} fullscreen={true} />}>
            <TermOfService />
          </Suspense>
        ),
        errorElement: <div>Not Found</div>,
      },
      {
        path: 'privacy-policy',
        element: (
          <Suspense fallback={<CusSpin spinning={true} fullscreen={true} />}>
            <PrivacyPolicy />
          </Suspense>
        ),
        errorElement: <div>Not Found</div>,
      },
    ],
  },
  // {
  //   path: '/login',
  //   element: (
  //     <Suspense fallback={<LoadingDiv />}>
  //       <Login />
  //     </Suspense>
  //   ),
  // },
]);

export default router;
