/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
// import { ReactComponent as FBLogo } from 'assets/btn-facebook.svg';
// import { ReactComponent as IGLogo } from 'assets/btn-instagram.svg';
// import { ReactComponent as XLogo } from 'assets/btn-x.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { CusDivider, CusParagraph, IconBtn } from 'components/index';
// import { SocialBtn } from 'components/index';
import { useCommon } from 'hooks/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import { fbUrl, igUrl, onGoToUrl, xUrl } from 'utils/common';

export const About: FC = () => {
  return (
    <Flex flex="0 0 165px" justify="center" align="center" css={cssAbout}>
      <Flex vertical align="center" className="sub-div">
        <TopDiv />
        <CusDivider marginBlock={0} />
        <Flex flex="0 0 auto" className="bottom-div">
          <CusParagraph
            text={'© 2024 Hiyo App, Inc.'}
            size={12}
            weight={600}
            color={'var(--any-grey-40)'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const TopDiv: FC = () => {
  const { t } = useTranslation(['action']);
  const { onGoHome, onGoPage } = useCommon();
  return (
    <Flex
      flex="1 1 auto"
      justify="space-between"
      align="center"
      className="top-div"
    >
      <Flex gap={69} align="center" className="item-div">
        <div className="logo">
          <IconBtn icon={<Logo />} onClick={onGoHome} />
        </div>
        <Flex gap={24} align="center">
          <IconBtn
            icon={<p>{t('action:about-us')}</p>}
            onClick={() => onGoPage('/aboutus')}
          />
          <IconBtn
            icon={<p>{t('action:terms-of-Service')}</p>}
            onClick={() => onGoPage('/terms')}
          />
          <IconBtn
            icon={<p>{t('action:privacy-policy')}</p>}
            onClick={() => onGoPage('/privacy-policy')}
          />
        </Flex>
      </Flex>
      {/* <Flex gap={24} align="center" className="item-div">
        <SocialBtn icon={<FBLogo />} onClick={() => onGoToUrl(fbUrl)} />
        <SocialBtn icon={<IGLogo />} onClick={() => onGoToUrl(igUrl)} />
        <SocialBtn icon={<XLogo />} onClick={() => onGoToUrl(xUrl)} />
      </Flex> */}
    </Flex>
  );
};

const cssAbout = css`
  width: 100%;
  padding-inline: 30px;
  background: var(--any-primary-10);
  > .sub-div {
    width: 1100px;
    height: 100%;
    > .top-div {
      width: 100%;
      flex: 1 1 auto;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 499px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      .item-div {
        @media screen and (max-width: 499px) {
          .logo {
            display: none;
          }
        }
        height: 100%;
      }
    }
    > .bottom-div {
      padding-block: 20px;
    }
  }
`;
