import { createSlice } from '@reduxjs/toolkit';

const initImgIndex: number = 0;

const productSlice = createSlice({
  name: 'product',
  initialState: {
    imgIndex: initImgIndex,
  },
  reducers: {
    setImgIndex: (state, action) => {
      state.imgIndex = action.payload;
    },
  },
});

export default productSlice.reducer;
export const selectProduct = (state: any) => state.product;

export const { setImgIndex } = productSlice.actions;


