import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
// 創建一個 axios instance
const apiClient = axios.create({
  baseURL: API_URL,
});

// const waitForAuthInit = (): Promise<User | null> => {
//   return new Promise((resolve) => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       unsubscribe();
//       resolve(user);
//     });
//   });
// };

// 在每一次的請求中加入 JWT
apiClient.interceptors.request.use(
  async (config) => {
    // @ts-ignore
    if (config.skipAuthCheck) {
      return config; // 如果设置了跳过身份验证检查的标志，则直接返回配置
    }
    // const user = auth.currentUser || (await waitForAuthInit());
    // if (user) {
    //   const token = await user.getIdToken();
    //   config.headers.Authorization = `Bearer ${token}`;
    // } else {
    //   window.location.href = '/login';
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 在每一次的回應中檢查狀態碼並處理 401 錯誤
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  // (error) => {
  //   if (error.response && error.response.status === 401) {
  //     window.location.href = '/login';
  //   }
  //   return Promise.reject(error);
  // }
);

export default apiClient;
