import { configureStore } from '@reduxjs/toolkit';

import searchReducer from './searchSlice';
import modalReducer from './modalSlice';
import productReducer from './productSlice';

export default configureStore({
  reducer: {
    search: searchReducer,
    modal: modalReducer,
    product: productReducer,
  },
});
