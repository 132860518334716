import seo from './seo.json';
import placeholder from './placeholder.json';
import action from './action.json';
import content from './content.json';
import options from './options.json';
import errMessage from './errMessage.json';
import aboutUs from './aboutUs.json';
import termOfService from './termOfService.json';
import privacyPolicy from './privacyPolicy.json';

const en = {
  seo,
  placeholder,
  action,
  content,
  options,
  errMessage,
  aboutUs,
  termOfService,
  privacyPolicy
};

export default en;
