import { App as AntApp, ConfigProvider } from 'antd';
import localeEn from 'antd/locale/en_US';
import localeZh from 'antd/locale/zh_TW';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import Router from './router/Router';

function App() {
  const { i18n } = useTranslation();
  const LNG = i18n.language;
  return (
    <ConfigProvider
      locale={LNG === 'zh' ? localeZh : localeEn}
      theme={{
        token: {
          // colorSplit: 'var(--grey-30)',
        },
        components: {
          Message: {
            zIndexPopup: 9999,
          },
          Form: {
            // labelFontSize: 14,
            // labelColor: 'var(--grey-50)',
            // labelRequiredMarkColor: 'var(--red-any-light)',
            // verticalLabelPadding: '0 0 8px',
            // labelColonMarginInlineEnd: 44,
            // itemMarginBottom: 16,
          },
          Slider: {
            // railBg: 'var(--grey-20)',
            // railHoverBg: 'var(--grey-20)',
          },
        },
      }}
    >
      <AntApp style={{ width: '100vw', height: '100dvh' }}>
        <RouterProvider router={Router} />
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
