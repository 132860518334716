/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { CusSpin } from 'components/index';
import { useProduct } from 'hooks/product/index';
import { Footer } from 'pages/footerBar/index';
import { FC } from 'react';
import { ItemDetail } from './detial/index';

interface IProductProps {}

const Product: FC<IProductProps> = (props) => {
  // const { t } = useTranslation(['action']);
  // const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useProduct();
  console.log('Product', data);
  return (
    <Flex vertical align="center" flex="1 1 auto" css={cssProduct}>
      <Flex vertical flex="1 1 auto" className="sub-div">
        {/* <Flex flex="0 0 auto" className="previous-btn">
          <CusButton
            text={t('action:previous')}
            type="link"
            onClick={() => navigate(-1)}
          />
        </Flex> */}
        <CusSpin spinning={isLoading && !isSuccess}>
          <ItemDetail data={data} />
        </CusSpin>
      </Flex>
      <Footer />
    </Flex>
  );
};
export default Product;

const cssProduct = css`
  width: 100%;
  overflow: auto;
  position: relative;
  > .sub-div {
    max-width: 1100px;
    width: 100%;
    /* height: 100%; */
    padding-inline: 30px;
    margin-block: 30px 169px;
    @media screen and (max-width: 1100px) {
      /* padding-top: 24px; */
      margin-block: 30px 72px;
      /* .previous-btn {
        display: none;
      } */
    }
  }
`;
