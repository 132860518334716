import action from './action.json';
import placeholder from './placeholder.json';
import seo from './seo.json';
import content from './content.json';
import options from './options.json';
import errMessage from './errMessage.json';
import aboutUs from './aboutUs.json';
import termOfService from './termOfService.json'
import privacyPolicy from './privacyPolicy.json'
const zhTW = {
  seo,
  placeholder,
  action,
  content,
  options,
  errMessage,
  aboutUs,
  termOfService,
  privacyPolicy
};

export default zhTW;
