/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ConfigProvider, Image } from 'antd';
import { FC, useState } from 'react';
interface ICusImageProps {
  src: string;
  alt?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  borderRadius?: number;
  wh?: string;
  bg?: string;
  isScale?: boolean;
  preview?: boolean;
}

export const CusImage: FC<ICusImageProps> = ({
  src,
  alt = 'image',
  objectFit = 'cover',
  borderRadius = 0,
  wh = '100%',
  bg = 'transparent',
  isScale = false,
  preview = false,
}) => {
  const [error, setError] = useState(false);
  return (
    <ConfigProvider
      theme={{
        components: {
          Image: {
            // zIndexPopup: 9999,
          },
        },
      }}
    >
      <div css={cssCusImage(objectFit, borderRadius, wh, bg, isScale)}>
        <Image
          // src={'/Spinner.svg'}
          src={src}
          alt={alt}
          preview={
            preview && src ? { destroyOnClose: true, forceRender: true } : false
          }
          width="100%"
          height="100%"
          placeholder={
            <Image
              // src={`${src}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
              src={'/Spinner.svg'}
              preview={false}
              width="100%"
              height="100%"
            />
          }
          onError={() => setError(true)}
          fallback={error ? '/img-fallback.png' : undefined}
        />
      </div>
    </ConfigProvider>
  );
};

const cssCusImage = (
  objectFit: string,
  borderRadius: number,
  wh: string,
  bg: string,
  isScale: boolean
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${wh};
  height: ${wh};
  overflow: hidden;
  border-radius: ${borderRadius}px !important;
  background: ${bg};
  /* box-shadow: 1px 0px 1px 1px rgba(0, 0, 0, 0.05); */
  img {
    /* width: 100%; */
    /* height: 100%; */
    transition: scale 0.2s ease-in-out 0s;
    object-fit: ${objectFit};
  }
  :hover {
    img {
      ${isScale ? 'scale: 1.1' : ''}
    }
  }
`;
