/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, ConfigProvider } from 'antd';
import { useBtnColor } from 'hooks/index';
import { FC, ReactNode } from 'react';
import { btnColorType } from 'type/components/button';

interface ICusButtonProps {
  text?: string;
  size?: 'large' | 'middle' | 'small';
  isBlock?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: 'primary' | 'default' | 'dashed' | 'link' | 'text';
  styleType?: btnColorType;
  isDanger?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  icon?: ReactNode | null;
  iconPosition?: 'start' | 'end';
  shape?: 'default' | 'circle' | 'round';
  radius?: number;
  height?: number;
  fontSize?: number;
  weight?: number;
}

export const CusButton: FC<ICusButtonProps> = ({
  text = 'NA',
  size = 'large',
  isBlock = false,
  loading = false,
  disabled = false,
  type = 'default',
  styleType = 'default',
  isDanger = false,
  htmlType = 'button',
  onClick = () => {},
  icon = null,
  iconPosition = 'start',
  shape = 'default',
  radius = 50,
  height = 50,
  fontSize = 16,
  weight = 600,
}) => {
  const { btnColors } = useBtnColor(styleType);

  return (
    <ConfigProvider
      // wave={{ disabled: true }}
      theme={{
        token: {
          // borderRadius: 6,
          borderRadiusLG: radius,
          // borderRadiusSM: 4,
          // controlHeight: 32,
          controlHeightLG: height,
          // controlHeightSM: 24,
        },
        components: {
          Button: {
            // contentFontSizeSM: 14,
            // contentFontSize: 14,
            contentFontSizeLG: fontSize,
            // contentLineHeightSM: 1.5714285714285714,
            // contentLineHeight: 1.5714285714285714,
            // contentLineHeightLG: 1.5,
            // dangerColor: "#ffffff",
            // dangerShadow: "0 2px 0 rgba(255, 38, 5, 0.06)",
            defaultActiveBg: btnColors.bgAc,
            defaultActiveBorderColor: btnColors.bordAc,
            defaultActiveColor: btnColors.txAc,
            defaultBg: btnColors.bg,
            defaultBorderColor: btnColors.bord,
            defaultColor: btnColors.tx,
            // defaultGhostBorderColor: "#ffffff",
            // defaultGhostColor: "#ffffff",
            defaultHoverBg: btnColors.bgH,
            defaultHoverBorderColor: btnColors.bordH,
            defaultHoverColor: btnColors.txH,
            // defaultShadow: "0 2px 0 rgba(0, 0, 0, 0.02)",
            fontWeight: weight,
            // ghostBg: "transparent",
            // groupBorderColor: "#4096ff",
            // linkHoverBg: "transparent",
            // onlyIconSize: 16,
            // onlyIconSizeLG: 18,
            // onlyIconSizeSM: 14,
            // paddingBlock: 4,
            paddingBlockLG: 8,
            // paddingBlockSM: 0,
            // paddingInline: 15,
            paddingInlineLG: 16,
            // paddingInlineSM: 7,
            // primaryColor: "var(--any-grey-70)",
            // primaryShadow: "0 2px 0 rgba(5, 145, 255, 0.1)",
            // textHoverBg: "rgba(0, 0, 0, 0.06)",
          },
        },
      }}
    >
      <Button
        autoInsertSpace={false}
        size={size}
        block={isBlock}
        icon={icon}
        iconPosition={iconPosition}
        shape={shape}
        type={type}
        danger={isDanger}
        htmlType={htmlType}
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        css={cssCusButton}
      >
        {text}
      </Button>
    </ConfigProvider>
  );
};

const cssCusButton = css`
  span {
    /* transition: font-size 0.1s ease-in-out 0s; */
    /* color: var(--grey-70); */
  }
  /* :hover {
    span {
      font-size: 1.1em;
    }
  } */
`;
