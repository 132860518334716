import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/index';
import zhCN from './locales/zhCN/index';
import zhTW from './locales/zhTW/index';

const resources = {
  en: en,
  'zh-TW': zhTW,
  'zh-CN': zhCN,
};

// console.log('lng', navigator.language);
const nowLng = localStorage.getItem('i18nextLng') || navigator.language;
const realLng = nowLng.startsWith('en') ? 'en' : nowLng;
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: realLng,
  fallbackLng: 'en',
});

export default i18n;
