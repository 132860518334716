/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { FC, ReactNode } from 'react';

interface IIconBtnProps {
  icon: ReactNode | string;
  onClick?: () => void;
  isScale?: boolean;
}

export const IconBtn: FC<IIconBtnProps> = ({
  icon,
  onClick = () => {},
  isScale = true,
}) => {
  return (
    <Flex
      flex={'0 0 auto'}
      justify={'center'}
      align="center"
      onClick={onClick}
      css={cssIconBtn(isScale)}
    >
      {icon}
    </Flex>
  );
};

const cssIconBtn = (isScale: boolean) => css`
  height: 100%;
  cursor: pointer;
  :hover {
    p {
      color: var(--grey-60);
    }
  }
`;
