// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setResetSearchData } from 'store/searchSlice';
import { useNavigate } from 'react-router-dom';

const useCommon = () => {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const onGoHome = () => {
    navigate('/', { replace: true });
    dispatch(setResetSearchData());
  };

  const onGoPage = (url: string) => {
    navigate(url);
  };

  return { onGoHome, onGoPage };
};

export { useCommon };
