/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { CusImage } from 'components/index';
import { ProductPhotoM } from 'components/md/index';
import { useWideScreen } from 'hooks/index';
import { usePhoto } from 'hooks/md/index';
import { FC } from 'react';
import { productDataType } from 'type/home/product';
import { ItemInfo, PhotoList } from './index';

interface IItemDetailProps {
  data: productDataType | undefined;
}

export const ItemDetail: FC<IItemDetailProps> = ({ data }) => {
  const { imgIndex, onOpenPhoto, onSelectImg } = usePhoto();
  const { isWideScreen } = useWideScreen(1100);

  return (
    <Flex flex="0 0 auto" css={cssItemDetail}>
      <ProductPhotoM />
      <PhotoList
        selectImg={imgIndex}
        onSelectImg={onSelectImg}
        list={data?.photos}
        className="photo-list-web"
      />
      <Flex vertical align="center" flex="0 0 auto" gap={8} className="sub-div">
        <Flex
          flex="0 0 auto"
          justify="center"
          align="center"
          className="main-img"
          onClick={() => (isWideScreen ? onOpenPhoto(data?.photos) : {})}
        >
          <CusImage
            src={data?.photos?.[imgIndex].photoUrl as string}
            alt={data?.photos?.[imgIndex].photoName}
            borderRadius={20}
            isScale={isWideScreen}
            // preview={isWideScreen}
          />
        </Flex>
        <PhotoList
          selectImg={imgIndex}
          onSelectImg={onSelectImg}
          list={data?.photos}
          className="photo-list-device"
          isVertical={false}
          gap={8}
          wh={70}
        />
        <ItemInfo data={data} className="info-device" />
      </Flex>
      <ItemInfo data={data} className="info-web" />
    </Flex>
  );
};

const cssItemDetail = css`
  width: 100%;
  height: 100%;
  margin-top: 16px;
  .main-img {
    cursor: pointer;
    width: 499px;
    height: 499px;
    margin-left: 16px;
  }
  .photo-list-device {
    display: none;
  }
  .info-device {
    display: none;
  }
  @media screen and (max-width: 1100px) {
    .sub-div {
      width: 100%;
    }
    .photo-list-web,
    .info-web {
      display: none;
    }
    .photo-list-device,
    .info-device {
      display: flex;
    }
    .main-img {
      cursor: default;
      max-width: 300px;
      max-height: 300px;
      margin-left: 0;
      margin-bottom: 16px;
    }
  }
`;
