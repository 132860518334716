/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { ReactComponent as IconAndroid } from 'assets/store-and.svg';
import { ReactComponent as IconApp } from 'assets/store-app.svg';
import { CusImage, CusParagraph, IconBtn } from 'components/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { androidUrl, iosUrl, onGoToUrl } from 'utils/common';

export const Banner: FC = () => {
  const { t } = useTranslation(['content']);
  return (
    <Flex flex="0 0 auto" justify="center" align="center" css={cssBanner}>
      <Flex justify="space-between" align="center" className="sub-div">
        <Flex vertical className="left-text-div" justify="center">
          <CusParagraph
            text={t('content:welcome-to')}
            size={20}
            weight={600}
            color={'var(--any-primary-60)'}
          />
          <CusParagraph
            text={t('content:shop-title')}
            size={36}
            weight={700}
            color={'var(--any-grey-70)'}
          />
          <CusParagraph
            text={t('content:footer-des')}
            size={16}
            color={'var(--any-grey-70)'}
          />
        </Flex>
        <Flex flex="1 1 auto" className="img-div">
          <CusImage
            src="/footer-banner-image.png"
            alt="banner"
            objectFit="contain"
          />
        </Flex>
        <Flex align="center" gap={12} className="app-div">
          <IconBtn
            icon={<IconApp />}
            onClick={() => onGoToUrl(iosUrl)}
            isScale={false}
          />
          <IconBtn
            icon={<IconAndroid />}
            onClick={() => onGoToUrl(androidUrl)}
            isScale={false}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const cssBanner = css`
  width: 100%;
  max-height: 300px;
  padding-inline: 30px;
  background: var(--any-primary-30);
  > .sub-div {
    width: 1100px;
    height: 100%;
    > .left-text-div {
      height: 100%;
    }
    > .img-div {
      height: 100%;
    }
    > .app-div {
      height: 100%;
    }
    @media screen and (max-width: 1100px) {
      justify-content: space-around;
      gap: 24px;
      .left-text-div {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      max-height: 200px;
      .app-div {
        display: none;
      }
    }
    @media screen and (max-width: 520px) {
      max-height: 150px;
      padding-inline: 0px;
    }
  }
  @media screen and (max-width: 499px) {
    padding-inline: 0px;
  }
`;
