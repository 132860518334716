/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex } from 'antd';
import { CusImage, CusModal } from 'components/index';
import { usePhoto } from 'hooks/md/index';
import { PhotoList } from 'pages/product/detial/index';
import { FC } from 'react';
import { photoType } from 'type/home/product';

interface IProductPhotoMProps {}

export const ProductPhotoM: FC<IProductPhotoMProps> = (props) => {
  const { photoOpen, photos, imgIndex, onClosePhoto, onSelectImg } =
    usePhoto();

  return (
    <>
      {photoOpen ? (
        <CusModal
          open={photoOpen}
          width={1078}
          content={
            <Content
              photos={photos}
              selectImg={imgIndex}
              onSelectImg={onSelectImg}
            />
          }
          onCancel={onClosePhoto}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const Content: FC<{
  photos: photoType[];
  selectImg: number;
  onSelectImg: (index: number) => void;
}> = ({ photos, selectImg, onSelectImg }) => {
  return (
    <Flex justify="center" gap={16} css={cssContent}>
      <Flex
        flex="0 0 auto"
        justify="center"
        align="center"
        className="main-img"
      >
        <CusImage
          src={photos?.[selectImg].photoUrl as string}
          alt={photos?.[selectImg].photoName}
          borderRadius={20}
          // preview={true}
        />
      </Flex>
      <PhotoList
        selectImg={selectImg}
        onSelectImg={onSelectImg}
        list={photos}
      />
    </Flex>
  );
};

const cssContent = css`
  width: 100%;
  .main-img {
    width: 740px;
    height: 740px;
  }
`;
