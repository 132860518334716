/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Flex, QRCode } from 'antd';
import { ReactComponent as IconAndroid } from 'assets/store-and.svg';
import { ReactComponent as IconIos } from 'assets/store-app.svg';
import { CusModal, IconBtn } from 'components/index';
import { useQrCode } from 'hooks/md/index';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { androidUrl, iosUrl, onGoToUrl, webUrl } from 'utils/common';

interface IQRCodeMProps {}

export const QRCodeM: FC<IQRCodeMProps> = (props) => {
  const { qrCodeOpen, onCloseQrCode } = useQrCode();

  return (
    <>
      {qrCodeOpen ? (
        <CusModal
          open={qrCodeOpen}
          content={<Content />}
          onCancel={onCloseQrCode}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const Content: FC = () => {
  const { t } = useTranslation(['content']);
  const [status, setStatus] = useState<'loading' | 'active'>('loading');

  useEffect(() => {
    setTimeout(() => {
      setStatus('active');
    }, 200);
  }, []);

  return (
    <Flex vertical align="center" css={cssQrCodeM}>
      <p className="title">{t('content:scan-qrcode')}</p>
      <p className="desc">{t('content:scan-qrcode-desc')}</p>
      <QRCode
        value={webUrl}
        icon="/favicon.ico"
        size={186}
        errorLevel="Q"
        style={{ border: 'none', marginBottom: 50 }}
        status={status}
      />
      <Flex gap={8} flex="0 0 auto" align="center" className="store-div">
        <IconBtn
          icon={<IconIos />}
          onClick={() => onGoToUrl(iosUrl)}
          isScale={false}
        />
        <IconBtn
          icon={<IconAndroid />}
          onClick={() => onGoToUrl(androidUrl)}
          isScale={false}
        />
      </Flex>
    </Flex>
  );
};

const cssQrCodeM = css`
  width: 100%;
  > .title {
    font-size: 36px;
    font-weight: 700;
    color: var(--grey-70);
    margin-bottom: 8px;
  }
  > .desc {
    font-size: 16px;
    color: var(--grey-50);
    text-align: center;
    margin-bottom: 40px;
  }
  > .store-div {
    margin-bottom: 80px;
  }
`;
