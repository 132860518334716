import { btnColorType } from 'type/components/button';

const useBtnColor = (colorType: btnColorType) => {
  const bg =
    colorType === 'default'
      ? 'var(--any-primary-default)'
      : colorType === 'grey10'
      ? 'var(--any-grey-10)'
      : '#000000';
  const bgH =
    colorType === 'default'
      ? 'var(--any-primary-50)'
      : colorType === 'grey10'
      ? 'var(--any-grey-20)'
      : '#000000';

  const bgAc =
    colorType === 'default'
      ? 'var(--any-primary-50)'
      : colorType === 'grey10'
      ? 'var(--any-grey-20)'
      : '#000000';

  const bord =
    colorType === 'default'
      ? 'var(--any-primary-default)'
      : colorType === 'grey10'
      ? 'var(--any-grey-10)'
      : '#000000';
  const bordH =
    colorType === 'default'
      ? 'var(--any-primary-50)'
      : colorType === 'grey10'
      ? 'var(--any-grey-20)'
      : '#000000';
  const bordAc =
    colorType === 'default'
      ? 'var(--any-primary-50)'
      : colorType === 'grey10'
      ? 'var(--any-grey-20)'
      : '#000000';

  const tx =
    colorType === 'default'
      ? 'var(--any-grey-70)'
      : colorType === 'grey10'
      ? 'var(--any-grey-70)'
      : '#000000';
  const txH =
    colorType === 'default'
      ? 'var(--any-grey-70)'
      : colorType === 'grey10'
      ? 'var(--any-grey-70)'
      : '#000000';
  const txAc =
    colorType === 'default'
      ? 'var(--any-grey-70)'
      : colorType === 'grey10'
      ? 'var(--any-grey-70)'
      : '#000000';

  const btnColors = {
    bg,
    bgH,
    bgAc,
    bord,
    bordH,
    bordAc,
    tx,
    txH,
    txAc,
  };

  return { btnColors };
};

export { useBtnColor };
