/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Flex } from 'antd';
import { Banner, About } from './index';

export const Footer: FC = () => {
  return (
    <Flex vertical flex="0 0 auto" css={cssFooter}>
      <Banner />
      <About />
    </Flex>
  );
};

const cssFooter = css`
  width: 100%;
`;
