import { createSlice } from '@reduxjs/toolkit';
import { SearchType } from 'type/search';

const initSearchData: SearchType = {
  keyword: '',
  city: null,
  age: [],
  condition: [],
  minPrice: null,
  maxPrice: null,
  sellerId: null,
  page: 1,
  take: 12,
};
const initPrices: string[] = [];
// const initAges: string[] = [];
// const initTypes: string[] = [];
const initExtraPrice: boolean = false;
const initExtraActive: boolean = false;
const initExtraKey: 'custom' | 'none' = 'none';

const resetStatus = (state: any) => {
  state.extraPrice = initExtraPrice;
  state.extraActive = initExtraActive;
  state.extraKey = initExtraKey;
  state.prices = initPrices;
  // state.ages = initAges;
  // state.types = initTypes;
};

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchData: initSearchData,
    loadMore: false,
    extraPrice: initExtraPrice,
    extraActive: initExtraActive,
    extraKey: initExtraKey,
    prices: initPrices,
    // ages: initAges,
    // types: initTypes,
    searchLoading: false,
  },
  reducers: {
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setSearchField: (state: any, action) => {
      const { field, value } = action.payload;
      state.searchData[field] = value;
    },
    setResetSearchData: (state) => {
      state.searchData = {
        keyword: initSearchData.keyword,
        city: initSearchData.city,
        age: initSearchData.age,
        minPrice: initSearchData.minPrice,
        maxPrice: initSearchData.maxPrice,
        condition: initSearchData.condition,
        sellerId: initSearchData.sellerId,
        page: initSearchData.page,
        take: initSearchData.take,
      };
      state.loadMore = false;
      resetStatus(state);
    },
    setResetFilter: (state) => {
      state.searchData = {
        keyword: state.searchData.keyword,
        city: initSearchData.city,
        age: initSearchData.age,
        minPrice: initSearchData.minPrice,
        maxPrice: initSearchData.maxPrice,
        condition: initSearchData.condition,
        sellerId: initSearchData.sellerId,
        page: initSearchData.page,
        take: state.searchData.take,
      };
      resetStatus(state);
    },
    setLoadMore: (state, action) => {
      state.loadMore = action.payload;
      if (action.payload) {
        state.searchData.take = 16;
      }
    },
    setSearchLoading: (state, action) => {
      state.searchLoading = action.payload;
    },
    setExtraPrice: (state, action) => {
      state.extraPrice = action.payload;
    },
    setExtraActive: (state, action) => {
      state.extraActive = action.payload;
    },
    setExtraKey: (state, action) => {
      state.extraKey = action.payload;
    },
    setPrices: (state, action) => {
      state.prices = action.payload;
    },
    // setAges: (state, action) => {
    //   state.ages = action.payload;
    // },
    // setTypes: (state, action) => {
    //   state.types = action.payload;
    // },
  },
});

export default searchSlice.reducer;
export const selectSearch = (state: any) => state.search;

export const {
  setSearchData,
  setSearchField,
  setResetSearchData,
  setResetFilter,
  setLoadMore,
  setSearchLoading,
  setExtraPrice,
  setExtraActive,
  setExtraKey,
  setPrices,
  // setAges,
  // setTypes,
} = searchSlice.actions;
