// import { useMutation, useQuery, UseQueryResult } from 'react-query';
import {
  useQuery,
  UseQueryResult,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';
import { productDataType, productsType } from 'type/home/product';
import { SearchType } from 'type/search';
import apiClient from './apiClient';
const exApiUrl = `products`;

// export const useGetProducts = (
//   param: SearchType,
//   loadMore?: boolean
// ): UseQueryResult<productsType> => {
//   return useQuery(
//     ['products-list', param,loadMore],
//     async () => {
//       const searchParams = new URLSearchParams();
//       !!param.keyword && searchParams.set('keyword', param.keyword);
//       !!param.age?.length &&
//         param.age.forEach((item) => {
//           searchParams.append('age', item);
//         });
//       !!param.condition?.length &&
//         param.condition.forEach((item) => {
//           searchParams.append('condition', item);
//         });
//       param.minPrice !== null &&
//         searchParams.set('minPrice', String(param.minPrice));
//       param.maxPrice !== null &&
//         searchParams.set('maxPrice', String(param.maxPrice));
//       // !!param.page && searchParams.set('page', String(param.page));
//       // !!param.take && searchParams.set('take', String(param.take));
//       if(!loadMore){
//         searchParams.set('page', String(1));
//         searchParams.set('take', String(12));
//       }
//       // searchParams.set('BeginDate', param.sDate);
//       const { data } = await apiClient.get(
//         `${exApiUrl}/list?${searchParams.toString()}`
//       );
//       return data;
//     },
//     {
//       // staleTime: Infinity,
//       // cacheTime: Infinity,
//       // refetchOnWindowFocus: false,
//       keepPreviousData: true,
//     }
//   );
// };

export const useGetProducts = (
  param: SearchType,
  loadMore?: boolean
): UseInfiniteQueryResult<productsType> => {
  return useInfiniteQuery(
    ['products-list', param],
    async ({ pageParam = 1 }) => {
      const searchParams = new URLSearchParams();
      !!param.keyword && searchParams.set('keyword', param.keyword);
      !!param.age?.length &&
        param.age.forEach((item) => {
          searchParams.append('age', item);
        });
      !!param.condition?.length &&
        param.condition.forEach((item) => {
          searchParams.append('condition', item);
        });
      param.minPrice !== null &&
        searchParams.set('minPrice', String(param.minPrice));
      param.maxPrice !== null &&
        searchParams.set('maxPrice', String(param.maxPrice));

      // 在這裡設置 page 和 take，並根據 pageParam 動態設置
      if(loadMore){

        searchParams.set('page', String(pageParam));
        searchParams.set('take', String(12)); // 假設每次拿 12 條數據
      }else{
        searchParams.set('page', String(1));
        searchParams.set('take', String(12));
      }

      const { data } = await apiClient.get(
        `${exApiUrl}/list?${searchParams.toString()}`
      );
      return data;
    },
    {
      // 告訴 useInfiniteQuery 如何取得下一頁的參數
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        // 假設 API 回傳的資料告訴我們有沒有下一頁
        return lastPage.metadata.hasNext ? nextPage : undefined;
      },
      // keepPreviousData: true,
    }
  );
};

export const useGetProductData = (
  productId: number
): UseQueryResult<productDataType> => {
  return useQuery(
    ['product-data', productId],
    async () => {
      const { data } = await apiClient.get(`${exApiUrl}/${productId}`);
      return data;
    },
    {
      enabled: !!productId,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

// export const usePostProduct = () => {
//   return useMutation(async (formData: LiveStreamProductType) => {
//     const { data } = await apiClient.post(
//       `${exApiUrl}/product/start-bidding`,
//       formData
//     );
//     return data;
//   });
// };

// export const usePutProduct = () => {
//   return useMutation(async (formData: LiveStreamProductType) => {
//     const { data } = await apiClient.put(
//       `${exApiUrl}/product/start-bidding`,
//       formData
//     );
//     return data;
//   });
// };

// export const usePutFinish = () => {
//   return useMutation(async (finishData: { id: number }) => {
//     const { data } = await apiClient.put(
//       `${exApiUrl}/product/finish-bidding`,
//       finishData
//     );
//     return data;
//   });
// };
