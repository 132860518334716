import { useDispatch, useSelector } from 'react-redux';
import { selectModal, setPhotoOpen, setPhotos } from 'store/modalSlice';
import { selectProduct, setImgIndex } from 'store/productSlice';
import { photoType } from 'type/home/product';

const usePhoto = () => {
  const dispatch = useDispatch();
  const { photoOpen, photos } = useSelector(selectModal);
  const { imgIndex } = useSelector(selectProduct);

  const onOpenPhoto = (photos: photoType[]|undefined) => {
    dispatch(setPhotoOpen(true));
    dispatch(setPhotos(photos));
  };

  const onClosePhoto = () => {
    dispatch(setPhotoOpen(false));
    dispatch(setPhotos([]));
  };

  const onSelectImg = (img: number) => {
    dispatch(setImgIndex(img));
  };

  return {
    photoOpen,
    photos,
    imgIndex,
    onOpenPhoto,
    onClosePhoto,
    onSelectImg,
  };
};

export { usePhoto };
